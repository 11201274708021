/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
}


#language {
  ul {
    display: flex;
    flex-direction: row;

    font-size: 30px;
    justify-content: center;
    padding: 2px 5px 0 5px;

    li {
      margin: 5px 15px;
    }
  }

  abbr {
    text-decoration: none;
    cursor: pointer;
    &.dotted {
      border: 2px dotted;
      padding: 10px;
    }
  }

  @media(max-width: 700px) {
     ul {
      font-size: 20px;
     }

  }
}

html {
  background: linear-gradient(180deg, #dfd8ff 0%, #FFFFFF 82.61%, #FFFFFF 82.62%, rgba(255, 255, 255, 0) 82.63%), no-repeat;
}

@font-face {
  font-family: 'Buffon Regular';
  src: url('./fonts/buffon-regular.otf');
}

.br {
  font-family: 'Buffon Regular';
}

@font-face {
  font-family: 'Buffon Thin';
  src: url('./fonts/buffon-thin.otf');
}

.bt {
  font-family: 'Buffon Thin';
}

@font-face {
  font-family: 'Compagnon Light Italic';
  src: url('./fonts/compagnon-lightitalic.otf');
}

.cli {
  font-family: 'Compagnon Light Italic';
}

@font-face {
  font-family: 'Compagnon Roman';
  src: url('./fonts/compagnon-roman.otf');
}

.cr {
  font-family: 'Compagnon Roman';
}

@font-face {
  font-family: 'Compagnon Light';
  src: url('./fonts/compagnon-light.otf');
}
.cl {
  font-family: 'Compagnon Light';
}

@font-face { 
  font-family: 'VG5000';
  src: url('./fonts/VG5000-regular.otf');
}

.vg {
  font-family: 'VG5000' !important;
}

body {
  font-family: 'Compagnon Light';
}

.logo {
  position: fixed;
  right: 30px;
  top: 15px;
}

.page-wrapper {
  margin: 20px 50px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 40px;

  @media(max-width: 1000px) {
    margin: 20px;
      grid-template-columns: 1fr 3fr;
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.left {
  text-align: center;
  .bad-bunny {
    max-width: 420px;
    @media(max-width: 1000px) {
      max-width: 220px;
  }
  }

  @media(max-width: 700px) {
    display: none;
    margin-top: 200px;
    h2 {
      font-size: 25px;
      text-align: left;
    }

    p {
      text-align: left;
      font-size: 15px;
    }
  }
}


.intro {
  font-size: 58px;
  max-width: 900px;
  .cr, .cl {
    font-size: 28px;
  }
  span {
    display: inline-block;
  }

  h1 {
    position: relative;
    margin: 10px auto;
  }

  p.cli {
    font-size: 20px;
  }

  a {
    text-decoration: none;
  }

  @media(max-width: 1000px) {
    font-size: 50px;

    p {
      font-size: 20px;
    }

    .cr, .cl {
      font-size: 18px;
    }
  }
}

.info-bubble {
  padding: 5px 5px 10px;
  background: black;
  color: white;
  position: absolute;
  bottom: -28px;
  left: 0;
  max-width: 650px;
  p {
    font-size: 20px;
  }

  @media(max-width: 700px) {
    bottom: 15px;
  }
}

.intro::selection {
  background: black;
  color: white;
}

.folder-wrapper {
  position: relative;

  @media(min-width: 700px) {
    margin-top: 40px;
  }
  margin-top: 20px;
}

.word-folder {
  cursor: pointer;
  position: absolute;
  background: url('imgs/folder.svg');
  background-size: contain;
  border-color: #767676;
  height: 349px;
  background-repeat: no-repeat;
  width: 469px;
  h2 {
    font-size: 2rem;
    margin-left: 10px;
  }

  p {
    font-size: 22px;
  }

  h3 {
    display: inline-block;
    font-size: 70px;
    vertical-align: top;
    margin-left: 13px;
  }
  

  .title {
    display: flex;
  }

  .appear {
    font-size: 15px;
  }

  li {
    font-size: 18px;
  }

  @media(max-width: 1050px) {
    height: 249px;
    width: 330px;

    h3 {
      font-size: 30px;
    }

    li {
      font-size: 12px;
    }

    h2 {
      font-size: 19px;
    }
  }

  @media (max-width: 700px) {
    height: 249px;
    width: 220px;

    h3 {
      font-size: 19px;
    }

    li {
      font-size: 10px;
    }

    h2 {
      font-size: 15px;
    }
    p {
      font-size: 15px;
    }
  }
}

.text-wrapper {
  margin-top: 20px;
  padding: 10px;

  @media(max-width: 700px) {
    margin-top: 3px;
  }
}


@media(min-width: 700px) {
  .react-draggable-dragging {
    filter: drop-shadow(rgb(0, 255, 31) 2px 4px 6px);
  }
}

.wrapper {
  margin: 44px 50px 50px ;
  display: grid;
  grid-template-columns: 1fr 1fr;

  img {
    max-width: 400px;
    margin-top: 100px;
  }

  @media(max-width: 1000px) {
    display: flex;
    flex-direction: column;
    margin: 10px 20px;

    img {
      max-width: 220px;
      margin-top: 5px;
    }
  }

  @media(min-width: 700px) and (max-width: 1000px)  {
    margin-top: 200px;
  }

  @media(max-width: 700px) {
    align-items: center;
    margin-top: 190px;
  }
}

.footer-text-wrapper {
  max-width: 600px;

  h2 {
    font-size: 30px;
    background: black;
    color: white;
    padding-bottom: 10px;
    display: inline-block;
    a:hover {
      background: white;
      color: black;
    }
  }

  .footer-text p {
    line-height: 1.2;
    font-size: 15px;
  }

  h3 {
    margin-top: 50px;
    margin-bottom: 10px;
    font-size: 40px;
    
  }
  p {
    font-size: 23px;
    line-height: 1.2;
  }

  a:hover {
    background: black;
    color: white;
  }

  @media(max-width: 1000px) {
    h3 {
      font-size: 30px;
      margin-top: 20px;
    }

    p {
      font-size: 16px;
    }

    h2 {
      font-size: 15px;
      color: black;
      background: unset;
    }

    .footer-text p {
      font-size: 11px;
    }
  }
}

.footer-text-right {
  @media(max-width: 700px) {
    display: none;
  }
}

.footer-text-left {
  display: none;
  @media(max-width: 700px) {
    display: block;
  }
}

.closer {
  font-size: 20px;
  line-height: 1.2;
  @media(max-width: 700px) {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
  }
}

.mobile-credits {
  margin: 24px 20px 30px 20px;
  text-align: center;
  @media(min-width: 700px) {
    display: none;
  }
  .bad-bunny {
    max-width: 220px;
  }
  h2 {
    font-size: 25px;
    text-align: left;
  }

  p {
    text-align: left;
    font-size: 15px;
  }
}